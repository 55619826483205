import { OrbitingCircles } from '@/shadcn-ui/components/ui/orbit';
import Image from 'next/image';

interface OrbitProps {
  smallestOrbitRadius: number;
  mediumOrbitRadius: number;
  largestOrbitRadius: number;
}

export function Orbit({
  smallestOrbitRadius,
  mediumOrbitRadius,
  largestOrbitRadius,
}: OrbitProps) {
  const largestOrbit = [
    { url: '/magazord-circle-logo.svg', delay: 27 },
    { url: '/vtex-circle-logo.svg', delay: 24 },
    { url: '/tray-circle-logo.svg', delay: 21 },
    { url: '/nuvemshop-circle-logo.svg', delay: 18 },
    { url: '/shopify-circle-logo.svg', delay: 15 },
    { url: '/vnda-circle-logo.svg', delay: 12 },
    { url: '/linx-commerce-circle-logo.svg', delay: 9 },
    { url: '/loja-integrada-circle-logo.svg', delay: 6 },
    { url: '/irroba-circle-logo.svg', delay: 3 },
    { url: '/shoppub-circle-logo.svg', delay: 0 },
  ];

  const mediumOrbit = [
    { url: '/wake-circle-logo.svg', delay: 38 },
    { url: '/uoou-solutions-circle-logo.svg', delay: 35 },
    { url: '/magento-circle-logo.svg', delay: 28 },
    { url: '/wbuy-circle-logo.svg', delay: 25 },
    { url: '/woo-circle-logo.svg', delay: 12 },
    { url: '/uappi-circle-logo.svg', delay: 1 },
  ];
  const smallestOrbit = [
    {
      url: '/google-circle-logo.svg',
      delay: 0,
    },
    {
      url: '/meta-circle-logo.svg',
      delay: 3.75,
    },
    {
      url: '/google-analytics-circle-logo.svg',
      delay: 7.5,
    },
    {
      url: '/google-search-console-circle-logo.svg',
      delay: 11.25,
    },
  ];

  return (
    <div className="h-full w-full flex-col bg-gradient-to-r from-[#1C45D6] to-[#7903EF] overflow-hidden">
      <div className="relative flex h-full w-full flex-col items-center justify-center bg-[url('/stars.svg')]">
        <div className="flex bg-white w-[60px] h-[60px] items-center justify-center rounded-lg">
          <Image
            height={50}
            width={50}
            alt="logo-adstart"
            src="/adstart-circle-logo.svg"
          />
        </div>
        {/* Primeira órbita (90px) - 4 elementos, espaçados 120° */}
        {smallestOrbit.map(({ url, delay }) => {
          return (
            <OrbitingCircles
              key={url}
              className="size-[50px] border-0 z-10"
              duration={15}
              delay={delay}
              radius={smallestOrbitRadius}
            >
              <Image height={64} width={64} alt={url} src={url} />
            </OrbitingCircles>
          );
        })}
        {/* Segunda órbita (120px) - 6 elementos, espaçados 120° */}
        {mediumOrbit.map(({ url, delay }) => {
          return (
            <OrbitingCircles
              key={url}
              className="size-[50px] border-0 z-10"
              duration={20}
              delay={delay}
              radius={mediumOrbitRadius}
            >
              <Image height={64} width={64} alt={url} src={url} />
            </OrbitingCircles>
          );
        })}
        {/* Terceira órbita - 10 elementos, ajustando delays */}
        {largestOrbit.map(({ url, delay }) => {
          return (
            <OrbitingCircles
              key={url}
              className="size-[50px] border-0 z-10"
              duration={30}
              delay={delay}
              radius={largestOrbitRadius}
            >
              <Image height={64} width={64} alt={url} src={url} />
            </OrbitingCircles>
          );
        })}
      </div>
    </div>
  );
}
